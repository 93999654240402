import $api from "../http";
import { AxiosResponse } from "axios";

export default class AppealService {

    static async appealsUpdate(offset, limit, filter='') {
        return $api.post(`/appeals-update`, {limit, offset, filter})
    }

    static async getAppeals(offset, limit, filter='') {
        return $api.post(`/appeals`, {limit, offset, filter})
    }

    static async getAppeal(appeal_id) {
        return $api.get(`/appeals/${appeal_id}`)
    }

}