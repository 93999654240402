import $api from "../http";
import { AxiosResponse } from "axios";

export default class BillingService {
    static async billing_users(offset, limit, filter='') {
        return $api.post('/billing_users', {limit, offset, filter})
    }

    static async billing_user(billing_id, user_id) {
        return $api.get(`/billing_users/${billing_id}/${user_id}`)
    }

    static async billing_user_accounts(billing_id, user_id) {
        return $api.get(`/billing_users/${billing_id}/${user_id}/accounts`)
    }

    static async billing_user_ip_services(billing_id, user_id) {
        return $api.get(`/billing_users/${billing_id}/${user_id}/ipservices`)
    }

}