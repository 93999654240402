import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const ContactBox = ({ title, subtitle, contacts = [] }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box width="100%" m="0 30px">
            {contacts.map((value) =>
                <Box display="flex" justifyContent="space-between">
                    <Box>
                        <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
                            <value.icon
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                            {value.value}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ContactBox;