import {
    Box,
    Button,
    IconButton,
    Typography,
    useTheme,
    Table,
    List,
    ListItem,
    Divider,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Stack, InputBase
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {useCallback, useEffect, useState, Fragment, useContext} from "react";
import {Link, useNavigate} from 'react-router-dom';
import AppealService from "../../services/AppealService";
import LinearProgress from '@mui/material/LinearProgress';
import {Search} from "@mui/icons-material";
import AppealListElement from "../../components/AppealListElement";
import ChatComponent from "./Chat";
import {Context} from "../../index";
import NoChat from "./NoAppeal";
import SearchIcon from "@mui/icons-material/Search";

function int2ip (ipInt) {
    return ( (ipInt>>>24) +'.' + (ipInt >> 16 & 255) +'.' + (ipInt>>8 & 255) +'.' + (ipInt & 255) );
}

const Appeals = () => {
    const {store} = useContext(Context);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const columns = [
        { field: 'created_on', headerName: 'created_on' },
        { field: 'provider_id', headerName: 'Provider' },
        { field: 'source_type', headerName: 'source_type ID' },
        { field: 'source', headerName: 'source' },
        // {
        //     field: 'full_name',
        //     headerName: 'Full name',
        //     flex: 1,
        //     cellClassName: "name-column--cell"
        // },
        // {
        //     field: 'ip',
        //     headerName: 'IP',
        //     flex: 1,
        //     renderCell: ({row: {ip}}) => {
        //         const listIp = ip.map((ip_number) =>
        //             <Typography>{int2ip(ip_number)}</Typography>
        //         );
        //         return (
        //             <Box>
        //                 {listIp}
        //             </Box>
        //         )
        //     }
        //
        // }
    ];

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0)

    const [appeal, setAppeal] = useState({
        'comments': []
    })

    const [appealId, setAppealId] = useState(false)
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [filterModel, setFilterModel] = useState({
        items: [],
        quickFilterValues: [''],
    })

    useEffect(() => {
        subscribe()
    }, [paginationModel, filterModel])

    const subscribe = async () => {
        try {
            const {data} = await AppealService.getAppeals(paginationModel.page, paginationModel.pageSize, filterModel.quickFilterValues[0]);
            setRows(data.appeals);
            setRowCount(data.totalCount)
            const appealsUpdate = await AppealService.appealsUpdate(paginationModel.page, paginationModel.pageSize, filterModel.quickFilterValues[0])
            subscribe()

        } catch (e) {
            setTimeout( () => {
                subscribe()
            }, 500)
        }
    }

    const subscribe_to_appeal = async (appeal_id) => {
        try {
            const {data} = await AppealService.getAppeal(appeal_id);
            setAppeal(data);
            setAppealId(data.id)
            // const appealsUpdate = await AppealService.appealsUpdate(paginationModel.page, paginationModel.pageSize, filterModel.quickFilterValues[0])
            // subscribe_to_appeal()
        } catch (e) {
            setTimeout( () => {
                // subscribe_to_appeal()
            }, 500)
        }
    }

    const handleRowClick = (params) => {
        navigate(`/billing_user/${params.row.billing_id}/${params.row.user_id}`)
    };

    const handleAppealClick = (appeal_id) => {
        store.setAppealId(appeal_id)
        subscribe_to_appeal(appeal_id)
    };

    return (
        <Box m="20px" >
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Appeals" subtitle="Appeals" />
            </Box>
            {/* CONTEXT */}
            <Stack direction="row" sx={{ width: "100%", height: "80vh"}} spacing={1.5}>
                <Box
                    sx={{
                        position: "relative",
                        height: "100%",
                        width: true ? 320 : "100vw",
                        backgroundColor:
                            theme.palette.mode === "light"
                                ? "#F8FAFF"
                                : theme.palette.background,

                        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                    }}
                >

                    <Stack spacing={2} sx={{ maxHeight: "100%" }}>
                        <Stack sx={{ width: "100%" }}>
                            <Box
                                display="flex"
                                backgroundColor={colors.primary[400]}
                                borderRadius="3px"
                                m={1}
                            >
                                <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                                <IconButton type="button" sx={{ p: 1 }}>
                                    <SearchIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                        <Stack sx={{ flexGrow: 1, overflow: "scroll" }}>
                            <Stack spacing={1}>
                                {rows.map((el) => {
                                    return <AppealListElement {...el} appeal_id={appealId} handleAppealClick={handleAppealClick}/>;
                                })}
                                </Stack>
                        </Stack>
                    </Stack>
                </Box>

                {/*chat*/}
                <Box
                    sx={{
                        position: "relative",
                        height: "100%",
                        width: 550
                    }}
                    backgroundColor={colors.primary[400]}
                >
                    {store.appealId !== null ? (
                        <ChatComponent />
                    ) : (
                    <Stack
                        spacing={2}
                        sx={{ height: "100%", width: "100%" }}
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <NoChat />
                        <Typography variant="subtitle2">
                            Select a conversation or start a{" "}
                            <Link
                                style={{
                                    color: theme.palette.primary.main,
                                    textDecoration: "none",
                                }}
                                to="/"
                            >
                                new one
                            </Link>
                        </Typography>
                    </Stack>
                    )}
                </Box>
                <Box
                    sx={{
                        position: "relative",
                        height: "100%",
                        width: 360,
                        // boxShadow

                    }}
                    // gridColumn="span 1"
                    backgroundColor={colors.primary[400]}
                    // display="flex"
                    // alignItems="center"
                    // justifyContent="center"
                >
                </Box>
            </Stack>
        </Box>
    )
};

export default Appeals;