import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import InfoBox from "../../components/InfoBox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import ContactBox from "../../components/ContactBox";
import {useEffect, useState} from "react";
import InfoDataGrid from "../../components/InfoDataGrid";
import DiagnosticDialog from "../../components/DiagnosticDialog";
import SwitchService from "../../services/SwitchService";
import {useParams} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";

function int2ip (ipInt) {
    return ( (ipInt>>>24) +'.' + (ipInt >> 16 & 255) +'.' + (ipInt>>8 & 255) +'.' + (ipInt & 255) );
}

const Host = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const params = useParams()
    const [host, setHost] = useState({
        'id': 'loading.....',
        'network': 'loading.....',
        'ip': 'loading.....',
        'mac': 'loading.....',
        'vendor': 'loading.....',
        'type': 'loading.....',
        'uplink': 'loading.....',
        'zbx_host_id': 'loading.....',
        'zbx_name': 'loading.....',
        'zbx_description': 'loading.....',
        'ipparents': 'loading.....',
        'portparents': 'loading.....',
        'created_on': 'loading.....',
        'updated_on': 'loading.....'
    })

    // Zabbix problems
    const [problem, setProblem] = useState([])
    function getRowId(row) {
        return row.eventid
    }
    const columnsProblem = [
        {
            field: 'clock',
            headerName: 'Clock',
            flex: 1,
            renderCell: ({ row: { clock } }) => {
                return (
                    new Date(clock * 1000).toLocaleString()
                )
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 2
        },
    ];

    // downlinks
    const [hostDownlinks, setHostDownlinks] = useState([])
    const columnsHostDownlinks = [
        // {
        //     field: 'clock',
        //     headerName: 'Clock',
        //     flex: 1,
        //     renderCell: ({ row: { clock } }) => {
        //         return (
        //             new Date(clock * 1000).toLocaleString()
        //         )
        //     }
        // },
        {
            field: 'portparents',
            headerName: 'Port',
            flex: 1
        },
        {
            field: 'ip',
            headerName: 'Host',
            flex: 10,
            renderCell: ({ row: { ip, uplink, vendor } }) => {
                return (
                    int2ip(ip) + ':' + uplink + '(' + vendor +')'
                )
            }
        },
    ];

    // ports
    const [hostPorts, setHostPorts] = useState([])
    const columnsHostPorts = [
        // {
        //     field: 'clock',
        //     headerName: 'Clock',
        //     flex: 1,
        //     renderCell: ({ row: { clock } }) => {
        //         return (
        //             new Date(clock * 1000).toLocaleString()
        //         )
        //     }
        // },
        {
            field: 'port',
            headerName: 'Port',
            flex: 1
        },
        {
            field: 'mac',
            headerName: 'Fdb',
            flex: 4,
                renderCell: ({ row: { mac, timestamp } }) => {
                    return (
                        <Box>
                            <Typography>
                                { mac }
                            </Typography>
                            <Typography>
                                { timestamp }
                            </Typography>
                        </Box>
                    )
                }
        },
        // {
        //     field: 'ip',
        //     headerName: 'Host',
        //     flex: 10,
        //     renderCell: ({ row: { ip, uplink, vendor } }) => {
        //         return (
        //             int2ip(ip) + ':' + uplink + '(' + vendor +')'
        //         )
        //     }
        // },
    ];

    const [expanded, setExpanded] = useState("hostPorts");
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        const fetcher = async () => {
            const responseHost = await SwitchService.getHost(params.networkid, params.hostip);
            setHost(responseHost.data.host)
            const responseZabbixProblems = await SwitchService.getHostProblem(params.networkid, params.hostip)
            setProblem(responseZabbixProblems.data.problems)
            const responseHostDownlinks = await SwitchService.getHostDownlinks(params.networkid, params.hostip)
            setHostDownlinks(responseHostDownlinks.data.hosts)
            const responseHostPorts = await SwitchService.getHostPorts(params.networkid, params.hostip)
            setHostPorts(responseHostPorts.data.ports)
        };
        fetcher()
    }, [])

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Host" subtitle="Host information"/>
            </Box>
            {/* CONTEXT */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                // gridAutoRows="240px"
                gap="20px"
            >
                {/* ROW 1 */}
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <InfoBox
                        title={host.ip}
                        subtitle={host.mac}
                        info={host.vendor}
                        icon={
                            <PersonOutlinedIcon
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>
            </Box>
            <Box
                // gridColumn="span 12"
                backgroundColor={colors.primary[400]}
                // display="flex"
                alignItems="center"
                justifyContent="center"
                mt="20px"
                // p="20px"
            >
                <Accordion expanded={expanded === 'zabbixProblems'} onChange={handleChange('zabbixProblems')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color={colors.greenAccent[500]} variant="h5">
                            Zabbix problems
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <DataGrid
                            columns={columnsProblem}
                            rows={problem}
                            getRowId={getRowId}
                            disableColumnSorting
                            disableColumnFilter
                            scrollbarSize={1}
                            rowHeight={20}
                            // autoPageSize
                            autoHeight={true}
                            pagination
                            // disableColumnSelector
                        />
                        {/*<InfoDataGrid*/}
                        {/*    columns={columnsPersonalAccounts}*/}
                        {/*    rows={billingUserAccounts}*/}
                        {/*    getRowId={getRowId}*/}
                        {/*/>*/}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'hostPorts'} onChange={handleChange('hostPorts')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color={colors.greenAccent[500]} variant="h5">
                            Ports
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InfoDataGrid
                            columns={columnsHostDownlinks}
                            rows={hostDownlinks}
                            // getRowId={getRowId}
                        />
                        <InfoDataGrid
                            columns={columnsHostPorts}
                            rows={hostPorts}
                            // getRowId={getRowId}
                        />
                    </AccordionDetails>
                </Accordion>



            </Box>
        </Box>

    )
};

export default Host;