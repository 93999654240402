import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
import Store from "./store/store";
import { BrowserRouter } from "react-router-dom";

import App from './App';

const store = new Store()

export const Context = createContext({
    store
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Context.Provider value={{
        store
        }}>

            <App />

        </Context.Provider>
    </BrowserRouter>
);

