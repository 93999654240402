import { Stack, Box } from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import { useTheme } from "@mui/material/styles";
// import { SimpleBarStyle } from "../../components/Scrollbar";

// import { ChatHeader, ChatFooter } from "../../components/Chat";
// import useResponsive from "../../hooks/useResponsive";
// import { Chat_History } from "../../data";
import {
    // DocMsg,
    // LinkMsg,
    // MediaMsg,
    // ReplyMsg,
    TextMsg,
    // Timeline,
} from "../../components/AppealCommentElement";
import AppealService from "../../services/AppealService";
import {Context} from "../../index";
import ChatHeader from "./Header";
import ChatFooter from "./Footer";
// import { useDispatch, useSelector } from "react-redux";
// import {
//     FetchCurrentMessages,
//     SetCurrentConversation,
// } from "../../redux/slices/conversation";
// import { socket } from "../../socket";

const Conversation = ({ isMobile, menu , comments}) => {
    // const dispatch = useDispatch();
    //
    // const { conversations, current_messages } = useSelector(
    //     (state) => state.conversation.direct_chat
    // );
    // const { room_id } = useSelector((state) => state.app);

    return (
        <Box p={isMobile ? 1 : 3}>
            <Stack spacing={3}>
                {comments.map((el) => {
                    switch (true) {
                        case true:
                            return (
                                // Text Message
                                <TextMsg el={el} menu={menu} />
                            );
                            default:
                                return <></>;
                    }
                })}
            </Stack>
        </Box>
    );
};

const ChatComponent = () => {
    const {store} = useContext(Context);
    const appealId = store.appealId
    // const isMobile = useResponsive("between", "md", "xs", "sm");
    const theme = useTheme();

    // const messageListRef = useRef(null);

    const [appeal, setAppeal] = useState({
        'comments': []
    })

    useEffect(() => {
        subscribe_to_appeal()
    }, [appealId]);

    const subscribe_to_appeal = async () => {
        try {
            const {data} = await AppealService.getAppeal(appealId);
            setAppeal(data);
            // const appealsUpdate = await AppealService.appealsUpdate(paginationModel.page, paginationModel.pageSize, filterModel.quickFilterValues[0])
            // subscribe_to_appeal()
        } catch (e) {
            setTimeout( () => {
                // subscribe_to_appeal()
            }, 500)
        }
    }

    return (
        <Stack
            height={"100%"}
            maxHeight={"100vh"}
            width={false ? "100vw" : "auto"}
        >
            {/*  */}
            <ChatHeader />
            <Box
                // ref={messageListRef}
                width={"100%"}
                sx={{
                    position: "relative",
                    flexGrow: 1,
                    overflow: "scroll",

                    backgroundColor:
                        theme.palette.mode === "light"
                            ? "#F0F4FA"
                            : theme.palette.background,

                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                }}
            >
                {/*<SimpleBarStyle timeout={500} clickOnTrack={false}>*/}

                <Conversation menu={true} isMobile={false} comments={appeal.comments} />

                {/*</SimpleBarStyle>*/}
            </Box>

            {/*  */}
            <ChatFooter />
        </Stack>
    );
};

export default ChatComponent;

export { Conversation };