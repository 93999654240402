import React, {useContext, useEffect, useState} from 'react';
import { Routes, Route } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Billings from "./scenes/billings";
import BillingUser from "./scenes/billing_user";
import Switches from "./scenes/switches";
import Appeals from "./scenes/appeals";
import Host from "./scenes/host";
import MainReportBilling from "./scenes/main_report_billing";
import Profile from "./scenes/profile";
import Natification from "./scenes/natification";
import Settings from "./scenes/settings";

// import UserService from "./services/UserService";

function App() {
    const {store} = useContext(Context);
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);

    // const [users, setUsers] = useState<IUser[]>([]);
    useEffect(() => {
        if (localStorage.getItem('token')) {
            store.checkAuth()
        }
    }, [])

    // async function getUsers() {
    //     try {
    //         const response = await UserService.fetchUsers();
    //         setUsers(response.data);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    if (store.isLoading) {
        return <div>Загрузка...</div>
    }

    if (!store.isAuth) {
        return (
            <div>
                <LoginForm/>
            </div>
        );
    }


    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    <Sidebar isSidebar={isSidebar} />
                    <main className="content">
                        <Topbar setIsSidebar={setIsSidebar} />
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/billings" element={<Billings />} />
                            <Route path="/billing_user/:billingid/:userid" element={<BillingUser />} />
                            <Route path="/switches" element={<Switches />} />
                            <Route path="/switches/:networkid/:hostip" element={<Host />} />
                            <Route path="/appeals" element={<Appeals />} />
                            <Route path="/main_report_billing" element={<MainReportBilling />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/natification" element={<Natification />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/logout" element={<LoginForm />} action={() => store.logout()} />
                        </Routes>


                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default observer(App);
