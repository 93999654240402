import axios from "axios";

// export const API_URL = '/api'
export const API_URL = 'https://crm-telecom.ru/api'
// export const API_URL = 'http://127.0.0.1:5003'

const $api = axios.create({
    // withCredentials: true,
    baseURL: API_URL,
})

$api.interceptors.request.use((config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}))

$api.interceptors.response.use((config) => {
    return config;
},async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            const config = {
                headers: { Authorization: `Bearer ${refreshToken}` }
            };
            const response = await axios.get(`${API_URL}/refresh`, config)
            const token = response.data.access_token;
            localStorage.setItem('token', token);
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return $api.request(originalRequest);
        } catch (e) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            console.log('НЕ АВТОРИЗОВАН')
        }
    }
    throw error;
})

export default $api;