import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";
import axios from "axios";
import {API_URL} from "../http";

export default class Store {
    user = {}
    appealId = null
    isAuth = false;
    isLoading = false;

    constructor() {
        makeAutoObservable(this)
    }

    setAuth(bool) {
        this.isAuth = bool;
    }

    setUser(user) {
        this.user = user;
    }

    setAppealId(appeal_id) {
        this.appealId = appeal_id;
    }

    setLoading(bool) {
        this.isLoading = bool;
    }

    async login(login, password){
        try {
            const response = await AuthService.login(login, password);
            localStorage.setItem('token', response.data.access_token)
            localStorage.setItem('refreshToken', response.data.refresh_token)
            this.setAuth(true)
            this.setUser(response.data.user)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async logout(){
        try {
            const response = await AuthService.logout();
            localStorage.removeItem('token')
            this.setAuth(false)
            // this.setUser(response.data.user)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async checkAuth(){
        this.setLoading(true);
        try {
            const response = await AuthService.getUser();
            this.setAuth(true)
            this.setUser(response.data.user)
        } catch (e) {
            console.log(e.response?.data?.message)
        } finally {
            this.setLoading(false);
        }

    }
}