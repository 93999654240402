import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";


const Natification = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Natification" subtitle="Your personal notifications" />

                {/*<Box>*/}
                {/*    <Button*/}
                {/*        sx={{*/}
                {/*            backgroundColor: colors.blueAccent[700],*/}
                {/*            color: colors.grey[100],*/}
                {/*            fontSize: "14px",*/}
                {/*            fontWeight: "bold",*/}
                {/*            padding: "10px 20px",*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <DownloadOutlinedIcon sx={{ mr: "10px" }} />*/}
                {/*        Download Reports*/}
                {/*    </Button>*/}
                {/*</Box>*/}
            </Box>
        </Box>
    )

};

export default Natification;