import { Box, Button, IconButton, Typography, useTheme, Table } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {useCallback, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import SwitchService from "../../services/SwitchService";
import LinearProgress from '@mui/material/LinearProgress';

function int2ip (ipInt) {
    return ( (ipInt>>>24) +'.' + (ipInt >> 16 & 255) +'.' + (ipInt>>8 & 255) +'.' + (ipInt & 255) );
}

const Switches = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const columns = [
        {
            field: 'ip',
            headerName: 'IP',
            flex: 1,
            renderCell: ({row: {ip}}) => {
                return (
                    <Box>
                        {int2ip(ip)}
                    </Box>
                )
            }

        },
        {
            field: 'zbx_name',
            headerName: 'Full name',
            flex: 1,
            cellClassName: "name-column--cell"
        }
    ];

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0)

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [filterModel, setFilterModel] = useState({
        items: [],
        quickFilterValues: [''],
    })

    useEffect(() => {
        const fetcher = async () => {
            if (localStorage.getItem('token')) {
                try {
                    const response = await SwitchService.getHosts(paginationModel.page, paginationModel.pageSize, filterModel.quickFilterValues[0]);
                    setRows(response.data.switches);
                    setRowCount(response.data.totalCount)
                } catch (e) {
                    console.log(e);
                }
            }
        };
        fetcher()
    }, [paginationModel, filterModel])

    const handleRowClick = (params) => {
        navigate(`/switches/${params.row.network}/${params.row.ip}`)
    };

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Switches" subtitle="Hosts" />
            </Box>
            <Box
                m="10px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                        // borderBottom: "none",
                    },
                    // "& .MuiDataGrid-columnHeaders": {
                    //     backgroundColor: colors.blueAccent[700],
                    //     // borderBottom: "none",
                    // },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    // "& .MuiDataGrid-footerContainer": {
                    //     borderTop: "none",
                    //     backgroundColor: colors.blueAccent[700],
                    // },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    onRowClick={handleRowClick}
                    columns={columns}
                    rows={rows}
                    rowCount={rowCount}
                    pageSizeOptions={[10,20,30,50,100]}
                    disableColumnSorting
                    disableColumnFilter
                    pagination
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    filterMode="server"
                    filterModel={filterModel}
                    onFilterModelChange={setFilterModel}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,

                        },
                    }}
                />
            </Box>
        </Box>
    )

};

export default Switches;