import $api from "../http";
import { AxiosResponse } from "axios";

export default class AuthService {
    static async login(login, password) {
        return $api.post('/login', {login, password})
    }

    static async logout() {
        return $api.get('/logout')
    }

    static async getUser() {
        return $api.get('/user')
    }
}