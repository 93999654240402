import $api from "../http";
import { AxiosResponse } from "axios";

export default class SwitchService {
    static async getHosts(offset, limit, filter='') {
        return $api.post(`/switches`, {limit, offset, filter})
    }

    static async getHost(network_id, host) {
        return $api.get(`/switches/${network_id}/${host}`)
    }

    static async getHostDownlinks(network_id, host) {
        return $api.get(`/switches/${network_id}/${host}/downlinks`)
    }

    static async getHostPorts(network_id, host) {
        return $api.get(`/switches/${network_id}/${host}/ports`)
    }

    static async getHostProblem(network_id, host) {
        return $api.get(`/switches/${network_id}/${host}/diagnostic`)
    }

    static async getPortDiagnostic(network_id, host, port) {
        return $api.post(`/switches/${network_id}/${host}/interface`, {"interface": port})
    }

    static async getHostArp(network_id, host, ipaddress) {
        return $api.post(`/switches/${network_id}/${host}/arp`, {"ipaddress": ipaddress})
    }

    static async getHostFdb(network_id, host, hostInterface) {
        return $api.post(`/switches/${network_id}/${host}/fdb`, {"interface": hostInterface})
    }

}